<template>
  <div v-if="bind.boolCarregado">
    <!--EDITAR-->
    <ed-modal v-model="bind.boolExibirCadastroAgendamento" width="100%">
      <div slot="title">
        {{ formData.strNome }}
        {{
          formData.intId
            ? ":: Agendamento #" + formData.intId
            : "Inserir Novo Agendamento"
        }}
      </div>

      <div class="" slot="content">
        <ed-editar
          v-if="!formData.intConsultaId"
          :key="'editar-' + $root.$session.versao"
          :formData="formData"
        />
        <ed-view
          v-if="formData.intId && formData.intConsultaId"
          :key="'view-' + $root.$session.versao"
          :formData="formData"
        />
      </div>
    </ed-modal>

    <ed-form :formData="formFilter" disableFixedSave>
      <span class="form-title text-left mb-3">Filtrar Dados do Agendamento</span>

      <ed-input-date
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pr-2"
        label="Data do Agendamento"
        name="arrayData"
        v-model="formFilter.arrayDataAgendamento"
        multiple
      />

      <ed-input-select
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pr-2"
        label="Ver agendamento por"
        name="strTipoVisualizacao"
        v-model="formFilter.strTipoVisualizacao"
        :items="bind.arrayTipoVisualizacao"
      />

      <ed-input-select
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pr-2"
        label="Status"
        name="intParametroStatusId"
        v-model="formFilter.arrayStatus"
        :items="bind.arrayAgendamentoStatus"
        multiple
      />

      <!-- <ed-input-select
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pr-2"
        label="Selecionar dias da semana"
        name="arrayDiasSemana"
        v-model="formFilter.arrayDiasSemana"
        :items="
          bind.arrayDiasSemana.filter(
            (x) =>
              !bind.objConfiguracaoAgendamento.strValor ||
              bind.objConfiguracaoAgendamento.strValor.arrayDiasAtendimento.indexOf(
                x.intId
              ) != -1
          )
        "
        multiple
        :disabled="formFilter.strTipoVisualizacao == 'lista'"
      /> -->

      <!-- <ed-input-select
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pr-2"
        label="Exibir intervalo de horário"
        name="intIntervalo"
        v-model="formFilter.intIntervalo"
        :items="bind.arrayIntervalo"
        :disabled="formFilter.strTipoVisualizacao == 'lista'"
      /> -->

      <ed-input-auto-complete
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pr-2"
        label="Médico"
        name="intProfissionalId"
        v-model="formFilter.intProfissionalId"
        route="Cadastro/Profissional"
        :filters="{
          intTipoProfissionalId: 269,
        }"
        @selected="setProfissional"
        :initializeItems="false"
        clearable
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-2"
        label="Pesquisar por nome"
        placeholder="Pesquisar por nome/cpf/cnpj do funcionário, empresa ou código do agendamento"
        name="strBusca"
        v-model="formFilter.strBusca"
      />
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-5 text-center">
        <ed-button
          label="Pesquisar"
          :iconLeft="$utilidade.getIcone('buscar')"
          @click="getAgendamento"
        />
      </div>

      <template v-if="formFilter.arrayDiasSemana && formFilter.arrayDiasSemana.length">
        <span class="form-title text-left mb-3">Agendamentos Efetuados</span>

        <ed-calendario
          v-if="formFilter.strTipoVisualizacao != 'lista'"
          :key="'calendario-' + $root.$session.versao"
          :formData="formFilter"
          :objResult="bind.objResult"
          @edit="onEdit"
          @register="onRegister"
          @delete="onDelete"
          @search="onSearch"
        />

        <ed-tabela
          v-if="formFilter.strTipoVisualizacao == 'lista'"
          strModel="Agendamento/Agendamento"
          :key="'tabela-' + $root.$session.versao"
          :formData="formFilter"
          :objResult="bind.objResult"
          @edit="onEdit"
          @register="onRegister"
        />
      </template>
    </ed-form>
  </div>
</template>

<script>
import {
  EdForm,
  EdInputSelect,
  EdInputDate,
  EdInputText,
  EdInputAutoComplete,
  EdButton,
  EdModal,
} from "@/components/common/form";

import EdCalendario from "./partials/calendario.vue";
import EdTabela from "./partials/tabela.vue";
import EdEditar from "./partials/editar.vue";
import EdView from "./partials/view.vue";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    EdForm,
    EdInputSelect,
    EdInputDate,
    EdInputAutoComplete,
    EdButton,
    EdCalendario,
    EdInputText,
    EdTabela,
    EdModal,
    EdEditar,
    EdView,
  },
  provide() {
    return {
      bind: this.bind,
      salvar: this.salvar,
      getAgendamento: this.getAgendamento,
      abrirConsulta: this.abrirConsulta,
      onEdit: this.onEdit,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolCarregado: false,
        boolExibirCadastroAgendamento: false,
        boolEditandoAgendamento: false,
        objConfiguracaoAgendamento: null,
        arrayHorarioAgendamento: [],
        arrayTipoVisualizacao: [
          { intId: "month", strNome: "Mês" },
          { intId: "week", strNome: "Semana" },
          { intId: "day", strNome: "Dia" },
          { intId: "4day", strNome: "Próximos 4 dias" },
          { intId: "category", strNome: "Por Status" },
          { intId: "lista", strNome: "Ver em lista" },
        ],
        arrayDiasSemana: [
          { intId: 0, strNome: "Domingo" },
          { intId: 1, strNome: "Segunda-Feira" },
          { intId: 2, strNome: "Terça-Feira" },
          { intId: 3, strNome: "Quarda-Feira" },
          { intId: 4, strNome: "Quinta-Feira" },
          { intId: 5, strNome: "Sexta-Feira" },
          { intId: 6, strNome: "Sábado" },
        ],
        arrayIntervalo: [
          { intId: 10, strNome: "10 Minutos" },
          { intId: 15, strNome: "15 Minutos" },
          { intId: 20, strNome: "20 Minutos" },
          { intId: 25, strNome: "25 Minutos" },
          { intId: 20, strNome: "20 Minutos" },
          { intId: 30, strNome: "25 Minutos" },
          { intId: 60, strNome: "1 hora" },
          { intId: 120, strNome: "2 horas" },
        ],
        objResult: null,
        arrayAgendamentoStatus: [],
        arrayParametroGrupo: [],
        arrayParametroSubGrupo: [],
        arrayAgendamentoPorData: [],
        arrayDatasDisponiveis: [],
        objStatusAtendimentoIniciado: null,
      },
      formFilter: {
        arrayDataAgendamento: [
          this.$utilidade.toDate(null, true, "DD/MM/YYYY"),
          this.$utilidade.addDate(7, null, "D", "DD/MM/YYYY"),
        ],
        arrayDatasBloqueadas: [],
        arrayHorariosBloqueados: [],
        arrayStatus: [],
        strDataAtualFormatado: this.$utilidade.toDate(null, true, "YYYY-MM-DD"),
        strTipoVisualizacao: this.$cookie.get("strAgendamentoTipoVisualizacao")
          ? this.$cookie.get("strAgendamentoTipoVisualizacao")
          : "lista",
        arrayDiasSemana: [],
        intProfissionalId: null,
        profissional: this.$root.$session.usuario.profissional
          ? this.$root.$session.usuario.profissional
          : null,
        intTotalIntervalo: 8,
        intIntervalo: 15,
        strHoraInicial: "08:00",
        strHoraFinal: "17:00",
      },
      formData: {},
    };
  },
  watch: {
    "formFilter.strTipoVisualizacao"() {
      this.$cookie.set(
        "strAgendamentoTipoVisualizacao",
        this.formFilter.strTipoVisualizacao,
        {
          expires: "10Y",
        }
      );
    },
    "formData.strDataAgendamento"() {
      this.getHorarioAgendamento();
    },
  },
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Agendamento/Agendamento", {
          boolInicializar: true,
        }),
      ]).then(([objDados]) => {
        if (objDados.status == 200 && objDados.result) {
          this.bind.arrayParametroGrupo =
            objDados.result.arrayParametro.consultaGrupo.parametros;
          this.bind.arrayAgendamentoStatus =
            objDados.result.arrayParametro.agendamentoStatus.parametros;
          this.bind.objStatusAtendimentoIniciado =
            objDados.result.arrayParametro.agendamentoStatusAtendimentoIniciado;

          this.bind.arrayAgendamentoStatus.forEach((element) => {
            if (
              [
                "agendamentoStatusCancelado",
                "agendamentoStatusAtendimentoFinalizado",
                "agendamentoStatusNaoCompareceu",
              ].indexOf(element.strChave) == -1
            ) {
              this.formFilter.arrayStatus.push(element.intId);
            }
          });

          //CONFIGURACAO DO AGENDAMENTO
          this.bind.objConfiguracaoAgendamento =
            objDados.result.arrayParametro.agendamentoConfiguracao;

          if (this.bind.objConfiguracaoAgendamento.strValor) {
            this.formFilter.strHoraInicial = this.bind.objConfiguracaoAgendamento.strValor.strHorarioAtendimentoInicial;
            this.formFilter.strHoraFinal = this.bind.objConfiguracaoAgendamento.strValor.strHorarioAtendimentoFinal;

            if (this.bind.objConfiguracaoAgendamento.strValor.intDuracaoAtendimento) {
              this.formFilter.intIntervalo = this.bind.objConfiguracaoAgendamento.strValor.intDuracaoAtendimento;
            }

            if (this.bind.objConfiguracaoAgendamento.strValor.arrayDiasAtendimento) {
              this.formFilter.arrayDiasSemana = this.bind.objConfiguracaoAgendamento.strValor.arrayDiasAtendimento;
            }

            if (this.bind.objConfiguracaoAgendamento.strValor.arrayDatasBloqueadas) {
              this.formFilter.arrayDatasBloqueadas = this.bind.objConfiguracaoAgendamento.strValor.arrayDatasBloqueadas;
            }

            if (this.bind.objConfiguracaoAgendamento.strValor.arrayHorariosBloqueados) {
              this.formFilter.arrayHorariosBloqueados = this.bind.objConfiguracaoAgendamento.strValor.arrayHorariosBloqueados;
            }
          }

          //AGENDAMENTO POR DATA
          this.bind.arrayAgendamentoPorData = objDados.result.arrayListaPorData;

          //DATAS DISPONIVEIS
          this.bind.arrayDatasDisponiveis = objDados.result.arrayDatasDisponiveis;

          //RESULTADO DOS AGENDAMENTOS
          this.bind.objResult = objDados.result.objListaAgendamento;
        }

        this.$root.$dialog.loading(false);
        this.bind.boolCarregado = true;
      });
    },

    setProfissional(obj) {
      this.formFilter.profissional = obj;
    },

    abrirConsulta(data) {
      this.$root.$dialog.newTab({
        name: "consulta.edit",
        params: { intId: data.intConsultaId },
      });
    },

    onRegister() {
      this.bind.boolExibirCadastroAgendamento = true;

      let objStatusAgendado = this.bind.arrayAgendamentoStatus.find(
        (c) => c.strChave == "agendamentoStatusAgendado"
      );

      this.formData = _.cloneDeep({
        intId: 0,
        boolFuncionarioNaoIdentificado: null,
        intCadastroGeralEmpresaId: null,
        intCadastroGeralUnidadeId: null,
        intCadastroGeralSetorId: null,
        intFuncionarioId: null,
        intFuncaoId: null,
        strDataAgendamento: null,
        strHoraAgendamentoInicial: null,
        strHoraAgendamentoFinal: null,
        intProfissionalId: null,
        exames: [],
        profissional: null,
        intParametroStatusId: objStatusAgendado ? objStatusAgendado.intId : null,
      });
    },

    onEdit(data) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Agendamento/Agendamento", { intId: data.intId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            objResponse.result.boolFuncionarioNaoIdentificado = true;

            if (!objResponse.result.intFuncionarioId) {
              objResponse.result.boolFuncionarioNaoIdentificado = false;
            }
            this.bind.boolExibirCadastroAgendamento = true;
            this.bind.boolEditandoAgendamento = true;
            this.bind.arrayHorarioAgendamento = [
              {
                intId: objResponse.result.strHoraAgendamentoInicial,
                strNome: objResponse.result.strHoraAgendamentoInicial,
              },
            ];

            this.formData = _.cloneDeep(objResponse.result);

            let self = this;
            setTimeout(() => {
              self.bind.boolEditandoAgendamento = false;
            }, 800);
          }
        });
    },

    onDelete(arrayId) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .delete("Agendamento/Agendamento", { arrayId: arrayId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getAgendamento();
          } else {
            this.$root.$dialog.error(objResponse.msg);
          }
        });
    },

    onSearch(arrayDate) {
      this.formFilter.arrayDataAgendamento = arrayDate;
      this.getAgendamento();
    },

    getHorarioAgendamento() {
      if (this.bind.boolEditandoAgendamento) return;

      this.bind.arrayHorarioAgendamento = [];

      if (!this.bind.boolEditandoAgendamento) {
        this.formData.strHoraAgendamentoInicial = null;
        this.formData.strHoraAgendamentoFinal = null;
        this.formData.intProfissionalId = null;
      }

      if (!this.formData.strDataAgendamento) {
        return;
      }

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Agendamento/Agendamento", {
          boolBuscarHorariosDisponiveis: true,
          strDataAgendamento: this.formData.strDataAgendamento,
          intProfissionalId: this.formData.intProfissionalId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          if (objResponse.status == 200 && objResponse.result) {
            this.bind.arrayHorarioAgendamento = objResponse.result;
          }
        });
    },

    getAgendamento() {
      this.bind.objResult = null;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Agendamento/Agendamento", this.formFilter)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.bind.boolCarregado = true;

          if (objResponse.status == 200 && objResponse.result) {
            this.bind.objResult = objResponse.result;
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Agendamento/Agendamento", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            this.onEdit(objResponse.result);
            this.getAgendamento();
          }
        });
    },
  },
};
</script>
