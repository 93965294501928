<template>
  <div>
    <ed-agendamento
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>

import EdAgendamento from "@/components/agendamento/index";
export default {
  name: "pageCadastro",
  props: {},
  components: {
     EdAgendamento
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

