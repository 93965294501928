<template>
  <div>
    <div
      class="
        col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
        mb-5
        pl-0
        text-left
      "
    >
      <ed-button
        label="Adicionar Agendamento"
        :iconLeft="$utilidade.getIcone('cadastrar')"
        @click="$emit('register', {})"
      />
    </div>

    <div
      class="
        col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
        mb-5
        pa-0
        text-center
      "
    >
      <div class="row">
        <div class="col-5 text-right">
          <a
            class="primary-color"
            @click="$refs.calendar.prev()"
            title="Retornar para data anterior"
          >
            <i :class="$utilidade.getIcone('setaDuplaEsquerda') + ' fa-3x'"></i>
          </a>
        </div>

        <div class="col-2">
          <span style="color: #238e85; font-size: 20pt">{{
            $utilidade.toDate(
              formData.strDataAtualFormatado,
              true,
              formData.strTipoVisualizacao == "month"
                ? "MMMM - YYYY"
                : "DD/MM/YYYY"
            )
          }}</span>
        </div>

        <div class="col-5 text-left">
          <a
            class="primary-color"
            @click="$refs.calendar.next()"
            title="Avançar para próxima data"
          >
            <i :class="$utilidade.getIcone('setaDuplaDireita') + ' fa-3x'"></i>
          </a>
        </div>
      </div>
    </div>

    <v-sheet height="600" v-if="formData">
      <v-calendar
        class="mb-5"
        ref="calendar"
        :key="key"
        v-model="formData.strDataAtualFormatado"
        :weekdays="[0, 1, 2, 3, 4, 5, 6]"
        :type="formData.strTipoVisualizacao"
        :events="arrayEventos"
        :interval-minutes="formData.intIntervalo"
        :first-time="formData.strHoraInicial"
        :first-interval="formData.arrayDataAgendamento[0]"
        :start="
          new Date(
            $utilidade.convertDate(
              formData.arrayDataAgendamento[0],
              'YYYY-MM-DD'
            )
          )
        "
        :end="formData.strDataAtualFormatado"
        :interval-count="formData.intTotalIntervalo"
        :short-intervals="false"
        :short-months="false"
        :short-weekdays="false"
        :event-color="formatEventColor"
        :categories="arrayCategoriaStatus"
        locale="pt-BR"
        :weekday-format="formatWeekDay"
        :day-format="formatDay"
        @change="getEvento"
        @click:event="onEdit"
        @click:time="onRegister"
        @click:day="onRegister"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { EdButton } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    objResult: {
      type: [Object],
      required: false,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdButton,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      arrayEventos: [],
      key: new Date().getTime(),
      arrayCategoriaStatus: [],
    };
  },
  watch: {
    objResult: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
    "formData.strDataAtualFormatado"() {
      this.onSearch();
    },
    "formData.strTipoVisualizacao"() {
      this.onSearch();
    },
  },
  computed: {},
  methods: {
    initialize() {
      this.$utilidade.console("AGENDAMENTO - CALENDARIO");

      this.formData.strDataAtualFormatado = this.$utilidade.convertDate(
        this.formData.arrayDataAgendamento[0],
        "YYYY-MM-DD"
      );

      this.getTotalIntervalo();
      this.key = new Date().getTime();
    },

    getTotalIntervalo() {
      let strDataInicial = new Date(
        this.$utilidade.toDate(null, true, "YYYY-MM-DD") +
          " " +
          this.formData.strHoraInicial
      );

      let strDataFinal = new Date(
        this.$utilidade.toDate(null, true, "YYYY-MM-DD") +
          " " +
          this.formData.strHoraFinal
      );
      let intTotalIntervalo = 0;

      for (
        var i = strDataInicial;
        i <= strDataFinal;
        i.setMinutes(i.getMinutes() + this.formData.intIntervalo)
      ) {
        intTotalIntervalo++;
      }

      return (this.formData.intTotalIntervalo = intTotalIntervalo);
    },

    onRegister({ date, time, year, month, day, hour, minute }) {
      return;
      /*
      let strDataAgendamentoFull = date + " " + time;
      let strHoraAgendamentoInicial = time;
      let strHoraAgendamentoFinal = this.$utilidade.addDate(
        15,
        strDataAgendamentoFull,
        "m",
        "HH:mm"
      );

      this.$emit("register", {
        intId: 0,
        strDataAgendamento: date,
        strHoraAgendamentoInicial: strHoraAgendamentoInicial,
        strHoraAgendamentoFinal: strHoraAgendamentoFinal,
        arrayHoraAgendamento: [
          strHoraAgendamentoInicial,
          strHoraAgendamentoFinal,
        ],
      });
      */
    },

    onEdit({ nativeEvent, event }) {
      if (event && event._data) {
        this.$emit("edit", event._data);
      }

      nativeEvent.stopPropagation();
    },

    onSearch() {
      let date = this.formData.strDataAtualFormatado;
      let date2 = this.$utilidade.addDate(7, date, "D", "YYYY-MM-DD");

      if (this.formData.strTipoVisualizacao == "month") {
        date2 = this.$utilidade.addDate(1, date, "M", "YYYY-MM-DD");
      } else if (
        this.formData.strTipoVisualizacao == "day" ||
        this.formData.strTipoVisualizacao == "category"
      ) {
        date2 = this.$utilidade.addDate(1, date, "D", "YYYY-MM-DD");
      } else if (this.formData.strTipoVisualizacao == "4day") {
        date2 = this.$utilidade.addDate(4, date, "D", "YYYY-MM-DD");
      }

      date = this.$utilidade.toDate(date, true, "DD/MM/YYYY");
      date2 = this.$utilidade.toDate(date2, true, "DD/MM/YYYY");

      let arrayDate = [date, date2];

      this.$emit("search", arrayDate);
    },

    formatEventColor(event) {
      return event.color;
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    getEvento({ start, end }) {
      let arrayEventos = [];
      this.arrayCategoriaStatus = [];

      if (this.objResult) {
        this.objResult.data.forEach((objAgendamento) => {
          let start = new Date(objAgendamento.strDataAgendamentoInicial);
          let end = new Date(objAgendamento.strDataAgendamentoFinal);

          if (
            this.arrayCategoriaStatus.indexOf(objAgendamento.status.strNome) ==
            -1
          ) {
            this.arrayCategoriaStatus.push(objAgendamento.status.strNome);
          }
          arrayEventos.push({
            name: "#" + objAgendamento.intId + " " + objAgendamento.strNome,
            start: start,
            end: end,
            color: objAgendamento.status.strValor.strCor
              ? objAgendamento.status.strValor.strCor
              : "grey",
            timed: true,
            category: objAgendamento.status.strNome,
            _data: objAgendamento,
          });
        });
      }

      var from = new Date(
        this.$utilidade.addDate(
          -1,
          this.formData.strDataAtualFormatado,
          "M",
          "YYYY-MM-DD"
        )
      );
      var to = new Date(
        this.$utilidade.addDate(
          2,
          this.formData.strDataAtualFormatado,
          "M",
          "YYYY-MM-DD"
        )
      );

      let strHoraInicial = this.formData.strHoraInicial;
      let strHoraFinal = this.formData.strHoraFinal;

      if (!strHoraInicial) {
        strHoraInicial = "08:00:00";
      }

      if (!strHoraFinal) {
        strHoraFinal = "18:00:00";
      }

      for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
        let intDiaSemana = day.getDay();
        let date = this.$utilidade.toDate(day, true, "YYYY-MM-DD");
        let start = new Date(date + " " + strHoraInicial);
        let end = new Date(date + " " + strHoraFinal);

        //VALIDAR DIA DA SEMANA
        if (
          this.formData.arrayDiasSemana &&
          this.formData.arrayDiasSemana.indexOf(intDiaSemana) == -1
        ) {
          arrayEventos.push({
            name: "Indisponível",
            start: start,
            end: end,
            color: "#c19393",
            timed: true,
            category: null,
            _data: null,
          });
        }

        //VALIDAR BLOQUEIO DE DATA
        if (
          this.formData.arrayDatasBloqueadas &&
          this.formData.arrayDatasBloqueadas.length
        ) {
          this.formData.arrayDatasBloqueadas.forEach((objItem) => {
            let strData = this.$utilidade.convertDate(
              objItem.strData,
              "YYYY-MM-DD"
            );

            if (strData == date) {
              arrayEventos.push({
                name: objItem.strObservacao
                  ? objItem.strObservacao
                  : "Indisponível",
                start: start,
                end: end,
                color: "#c19393",
                timed: true,
                category: null,
                _data: null,
              });
            }
          });
        }

        //VALIDAR BLOQUEIO DE HORÁRIO
        if (
          this.formData.arrayHorariosBloqueados &&
          this.formData.arrayHorariosBloqueados.length
        ) {
          this.formData.arrayHorariosBloqueados.forEach((objItem) => {
            let strData = objItem.strData
              ? this.$utilidade.convertDate(objItem.strData, "YYYY-MM-DD")
              : date;
            let strDataInicial = this.$utilidade.toDate(
              strData + " " + objItem.strHorarioInicial,
              false,
              "YYYY-MM-DD HH:mm"
            );
            let strDataFinal = this.$utilidade.toDate(
              strData + " " + objItem.strHorarioFinal,
              false,
              "YYYY-MM-DD HH:mm"
            );

            if (strData == date && [0,6].indexOf(intDiaSemana) == -1) {
              arrayEventos.push({
                name: objItem.strObservacao
                  ? objItem.strObservacao
                  : "Indisponível",
                start: new Date(strDataInicial),
                end: new Date(strDataFinal),
                color: "#c19393",
                timed: true,
                category: null,
                _data: null,
              });
            }
          });
        }
      }

      this.arrayEventos = arrayEventos;
    },

    formatDay(objDay) {
      return this.$utilidade.toDate(objDay.date, true, "DD/MM");
    },

    formatWeekDay(objCalendario) {
      switch (objCalendario.weekday) {
        case 0:
          return "Dom";
          break;

        case 1:
          return "Seg";
          break;

        case 2:
          return "Ter";
          break;

        case 3:
          return "Qua";
          break;

        case 4:
          return "Qui";
          break;

        case 5:
          return "Sex";
          break;

        case 6:
          return "Sáb";
          break;
      }
    },
  },
};
</script>
