<template>
  <div v-if="bind.booCarregando">
    <ed-alert class="text-left" type="warning" v-if="bind.objResult && bind.objResult.arrayExames.length">
      Os exames a seguir é uma prévia e poderá ter alterações
    </ed-alert>

    <ed-alert  class="text-left" type="error" v-if="bind.strError">
      {{bind.strError}}
    </ed-alert>

    <ed-alert  class="text-left" type="warning" v-if="bind.objResult && !bind.objResult.objUltimaRevisao">
     Empresa sem PCMSO
    </ed-alert>

    <ed-simple-table
      v-if="bind.objResult && bind.objResult.arrayExames.length"
      disabledSearchRows
      disabledRegisterRows
    >
      <thead>
        <tr>
          <th width="40">#</th>
          <th>Exame</th>
          <th>Data</th>
          <th>Valor Estimado</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(objConsultaExame, index) in bind.objResult.arrayExames">
          <tr :key="index" v-if="!objConsultaExame.aproveitamento">
            <td align="left">{{ index + 1 }}</td>
            <td align="left">
              <ed-collapse
                :textValue="objConsultaExame.exame.strNome"
                :textLimit="30"
              />
            </td>
            <td align="left">
              {{$utilidade.toDate(objConsultaExame.strDataExame,true)}}
            </td>
            <td align="left">
              R$ {{$utilidade.floatToMoney(objConsultaExame.floatValorVenda)}}
            </td>
            <td align="left">
            </td>
          </tr>
        </template>

        <template v-for="(objConsultaExame, index) in bind.objResult.arrayExames">
          <tr
            class="ed-consulta-aproveitamento"
            :key="index"
            v-if="objConsultaExame.aproveitamento"
          >
            <td align="left"></td>
            <td align="left">
              <router-link
                :to="{
                  name: 'consulta.edit',
                  params: { intId: objConsultaExame.aproveitamento.intId },
                }"
                target="_blank"
                :title="
                  'Exame aproveitado da consulta ' +
                  objConsultaExame.aproveitamento.intId +
                  '. Clique para visualizar a consulta'
                "
              >
                <i :class="$utilidade.getIcone('link')" />
              </router-link>
              <ed-collapse
                :textValue="objConsultaExame.exame.strNome"
                :textLimit="30"
              />
            </td>
            <td align="left">
              {{ $utilidade.toDate(objConsultaExame.strDataExame, true) }}
            </td>
            <td align="left">---</td>
            <td align="left"></td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="bind.objResult.arrayExames.length">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th align="right">
            <h6><b>R$ {{ getTotalExames() }}</b></h6>
          </th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputDate,
  EdInputText,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
  EdAlert,
  EdCollapse,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputText,
    EdButton,
    EdInputDate,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
    EdAlert,
    EdCollapse,
  },
  inject: ["bind"],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
     bind:{
      booCarregando:false,
      strError:null,
      objResult:null,
     }
    };
  },
  watch: {
    'formData.intFuncionarioId'(){
      this.initialize();
    },
    'formData.intCadastroGeralUnidadeId'(){
      this.initialize();
    },
    'formData.intCadastroGeralSetorId'(){
      this.initialize();
    },
    'formData.intParametroSubGrupoId'(){
      this.initialize();
    }
  },
  computed: {},
  methods: {
    initialize() {
      this.getPreviaExames();
    },

    getTotalExames(){
      let floatTotal = 0;

      
      if(this.bind.objResult && this.bind.objResult.arrayExames.length){
        this.bind.objResult.arrayExames.forEach(element => {
          if(!element.aproveitamento){
            floatTotal += Number(element.floatValorVenda)
          }
          
        });
      }

      return this.$utilidade.floatToMoney(floatTotal)

    },

    getPreviaExames() {
      this.bind.strError = null;
      this.bind.objResult = null;

      this.formData.boolListarPreviaExames = true;

      this.$root.$request
        .get("Consulta/Consulta", this.formData)
        .then((objResponse) => {
          this.bind.booCarregando = true;
          
          if(objResponse.status == 200){
            this.bind.objResult = objResponse.result
          }
          else{
            this.bind.strError = objResponse.msg
          }
        });
    },

  },
};
</script>
