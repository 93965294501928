<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Agendamento/Agendamento"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objResult"
      :filter="formData"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getAgendamento"
      :config="{
        registerName: 'Adicionar Agendamento',
        searchName: 'Pesquisar pelo nome ou CPF do funcionário',
      }"
    />

  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    objResult: {
      type: [Object],
      required: false,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdTable,
  },
  inject: ["getAgendamento", "abrirConsulta"],
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
          width: "100px",
        },
        {
          text: "Data Agendamento",
          sortable: true,
          value: "strDataAgendamento",
        },
        {
          text: "Funcionário",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Empresa",
          sortable: true,
          value: "strNomeEmpresa",
        },
        {
          text: "Médico",
          sortable: true,
          value: "strNomeMedico",
        },
        {
          text: "Status",
          sortable: true,
          value: "intParametroStatusId",
        },
      ],
      arrayRows: [],
    };
  },
  watch: {
    objResult: {
      handler: function (newVal) {
        this.loading = true;
        this.initialize();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {
      this.$utilidade.console("AGENDAMENTO - TABELA");

      this.loading = false;
      this.arrayRows = [];
      let arrayRows = [];

      if (!this.objResult) return;

      this.objResult.data.forEach((objAgendamento) => {
        let strDataAgendamento = this.$utilidade.toDate(
          objAgendamento.strDataAgendamento,
          true
        );
        let arrayDadosAdicionais = [];
        let arrayDadosAdicionaisEmpresa = [];

        strDataAgendamento +=
          " " +
          this.$utilidade.toDate(
            objAgendamento.strDataAgendamentoInicial,
            false,
            "HH:mm"
          ) +
          " - " +
          this.$utilidade.toDate(
            objAgendamento.strDataAgendamentoFinal,
            false,
            "HH:mm"
          );

        if (objAgendamento.strCpf) {
          arrayDadosAdicionais.push(
            "<br>CPF: " + this.$utilidade.formataCpf(objAgendamento.strCpf)
          );
        }

        if (objAgendamento.funcao) {
          arrayDadosAdicionais.push(
            "<br>FUNÇÃO: " + objAgendamento.funcao.strNome
          );
        }

        if (objAgendamento.unidade) {
          arrayDadosAdicionaisEmpresa.push(
            "<br>UNIDADE: " + objAgendamento.unidade.strNome
          );
        }

        if (objAgendamento.setor) {
          arrayDadosAdicionaisEmpresa.push(
            "<br>SETOR: " + objAgendamento.setor.strNome
          );
        }

        if (!objAgendamento.intFuncionarioId) {
          arrayDadosAdicionais.push(
            "<br><label style='color:red'>Funcionário não identificado</label>"
          );
        }

        let item = {
          intId: objAgendamento.intId,
          strNome:
            objAgendamento.strNome +
            '<span style="color:grey;font-size:9pt">' +
            arrayDadosAdicionais.join("") +
            "</span>",
          strDataAgendamento: strDataAgendamento,
          strNomeMedico: objAgendamento.profissional
            ? objAgendamento.profissional.strNome
            : "---",
          intParametroStatusId:
            '<span style="color:' +
            objAgendamento.status.strValor.strCor +
            '">' +
            objAgendamento.status.strNome +
            "</span>",
          strNomeEmpresa: objAgendamento.empresa
            ? objAgendamento.empresa.strNome +
              '<span style="color:grey;font-size:9pt">' +
            arrayDadosAdicionaisEmpresa.join("") +
            "</span>"
            : "---",
          buttons: [],
          disableDelete:
            [
              "agendamentoStatusAtendimentoFinalizado",
              "agendamentoStatusCancelado",
              "agendamentoStatusNaoCompareceu",
            ].indexOf(objAgendamento.status.strChave) != -1,
          _item: objAgendamento,
        };

        if (objAgendamento.intConsultaId) {
          item.buttons.push({
            icone: this.$utilidade.getIcone("consulta"),
            color: "warning",
            title: "Consulta gerado com sucesso. Clique para visualizar.",
            click: this.abrirConsulta,
          });
        }

        arrayRows.push(item);
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.$emit("register", {});
    },

    onEdit(data) {
      this.$emit("edit", data);
    },

    onDelete(data) {
      this.$emit("delete", data);
    },
  },
};
</script>
