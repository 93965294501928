<template>
  <div>
    <!--NOVA CONSULTA-->
    <ed-modal v-model="boolExibirFormularioNovaConsulta" width="50%">
      <div slot="title">Gerenciar Consulta</div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataCustomizado"
          :handlerSave="gerarConsulta"
          labelButton="Gerar Consulta"
          disableFixedSave
        >
          <div class="row">
            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 pl-3 pr-1"
              v-model="formDataCustomizado.strDataCheckin"
              label="Data Checkin do Funcionário"
              name="strDataCheckin"
              rules="required"
            />

            <ed-input-time
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 pr-1"
              v-model="formDataCustomizado.strHoraCheckin"
              label="Hora Checkin do Funcionário"
              name="strHoraCheckin"
              rules="required"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0"
              v-model="formDataCustomizado.strCpf"
              label="CPF"
              name="strCpf"
              v-mask="'999.999.999-99'"
              :disabled="boolCamposBloqueado"
              rules="required|cpf"
            />

            <!-- <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                pl-3
              "
              v-model="formDataCustomizado.strObservacao"
              label="Observações"
              name="strObservacao"
            /> -->
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      savePosition="absolute"
      :block="boolAgendamentoBloqueado"
    >
      <div class="row ma-0 pa-0">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
          <span class="form-title text-left mb-3">Data do Agendamento</span>

          <div class="row ma-0 mt-3 mb-3">
            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
              v-model="formData.strDataAgendamento"
              label="Data do Agendamento"
              name="strDataAgendamento"
              rules="required"
              :getAllowedDate="getAllowedDate"
              :getEventColor="getEventColor"
            />

            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
              v-model="formData.strHoraAgendamentoInicial"
              label="Horário do Agendamento"
              name="strHoraAgendamentoInicial"
              rules="required"
              :disabled="!formData.strDataAgendamento"
              :items="bind.arrayHorarioAgendamento"
              :placeholder="
                formData.strDataAgendamento
                  ? 'Selecione'
                  : 'Seleciona a data do agendamento'
              "
            />

            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pa-0 ma-0 pr-1"
              v-model="formData.intParametroGrupoId"
              name="intParametroGrupoId"
              rules="required"
              label="Tipo de Consulta"
              :items="bind.arrayParametroGrupo"
              :disabled="boolCamposBloqueado"
              @selected="setGrupo"
            />

            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pa-0 ma-0 pr-1"
              v-model="formData.intParametroSubGrupoId"
              name="intParametroSubGrupoId"
              rules="required"
              :label="formData.grupo.strValor.strLabel"
              :items="formData.grupo.parametros"
              @selected="setSubGrupo"
              :disabled="boolCamposBloqueado"
              v-if="formData.grupo"
            />

            <ed-input-select
              v-if="
                formData.sub_grupo &&
                formData.sub_grupo.strValor &&
                formData.sub_grupo.strValor.meses &&
                formData.sub_grupo.strValor.meses.length
              "
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pa-0 ma-0"
              v-model="formData.intMeses"
              name="intMeses"
              label="Periodicidade do Exame"
              rules="required"
              :items="formData.sub_grupo.strValor.meses"
              :disabled="boolCamposBloqueado"
            />
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
          <span class="form-title text-left mb-3">Outras Informações</span>

          <div class="row ma-0 mt-3 mb-3">
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
              v-model="formData.intProfissionalId"
              label="Médico"
              name="intProfissionalId"
              item-text="strNome"
              route="Cadastro/Profissional"
              :filters="{
                intTipoProfissionalId: 269,
              }"
              :itemList="formData.profissional ? [formData.profissional] : []"
              :initializeItems="formData.intId == 0 ? true : false"
            />
            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
              v-if="!boolAgendamentoBloqueado"
              v-model="formData.intParametroStatusId"
              label="Status do Agendamento"
              name="intParametroStatusId"
              rules="required"
              :items="arrayAgendamentoStatus"
              :disabled="boolCamposBloqueado"
              @selected="setStatus"
            />

            <ed-input-text-area
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0"
              v-model="formData.strObservacao"
              label="Observações"
              name="strObservacao"
              :disabled="boolCamposBloqueado"
            />
          </div>
        </div>

        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 text-left"
          v-if="!boolCamposBloqueado && formData.intParametroGrupoId"
        >
          <span class="form-title text-left mb-3">Informações do Funcionário</span>

          <v-tabs v-model="strTab" centered icons-and-text>
            <v-tab href="#tab-funcionario">
              Funcionário
              <i :class="$utilidade.getIcone('usuario') + ' fa-2x'"></i>
            </v-tab>
            <v-tab href="#tab-exame-complemetares" v-if="!boolCamposBloqueado">
              Exames Complementares
              <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
            </v-tab>
            <v-tab
              href="#tab-exame-previa"
              v-if="
                !boolCamposBloqueado &&
                formData.grupo &&
                formData.grupo.strChave == 'consultaSubGrupoAso'
              "
              :disabled="boolCamposBloqueado || !boolExibirPrevia"
            >
              Prévia de Exames a Realizar
              <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
            </v-tab>
          </v-tabs>

          <div class="row ma-0 mt-3 mb-3" v-if="strTab == 'tab-funcionario'">
            <ed-input-checkbox
              v-model="formData.boolFuncionarioNaoIdentificado"
              class="col-12 pa-0 ma-0"
              name="boolFuncionarioNaoIdentificado"
              labelItem="Selecionar funcionário existente"
              inline
              :disabled="
                boolCamposBloqueado || (formData.intId && formData.intFuncionarioId)
                  ? true
                  : false
              "
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
              v-if="formData.boolFuncionarioNaoIdentificado"
              v-model="formData.intFuncionarioId"
              label="Funcionário"
              name="intFuncionarioId"
              item-text="strNome"
              route="Cadastro/Funcionario"
              rules="required"
              :filters="{ boolMostrarEmpresas: true }"
              :itemList="formData.funcionario ? [formData.funcionario] : []"
              :initializeItems="!formData.intId ? true : false"
              :disabled="boolCamposBloqueado"
              @selected="setFuncionario"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
              v-if="formData.boolFuncionarioNaoIdentificado"
              v-model="formData.intCadastroGeralEmpresaId"
              label="Empresa"
              name="intCadastroGeralEmpresaId"
              item-text="strNome"
              route="Cadastro/CadastroGeral"
              :filters="{ intTipoCadastroGeralId: 58 }"
              :itemList="formData.empresa ? [formData.empresa] : []"
              :initializeItems="false"
              :disabled="boolCamposBloqueado || !formData.funcionario"
              clearable
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 pr-1"
              v-if="formData.boolFuncionarioNaoIdentificado"
              v-model="formData.intCadastroGeralUnidadeId"
              label="Unidade de Trabalho"
              name="intCadastroGeralUnidadeId"
              item-text="strNome"
              route="Cadastro/CadastroGeralUnidade"
              :itemList="formData.unidade ? [formData.unidade] : []"
              :filters="{
                intCadastroGeralEmpresaId: formData.intCadastroGeralEmpresaId,
              }"
              :initializeItems="
                !formData.intId && formData.intCadastroGeralEmpresaId ? true : false
              "
              :disabled="boolCamposBloqueado || !formData.intCadastroGeralEmpresaId"
              clearable
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-4 col-lg-6=4 col-xl-4 pa-0 pr-1"
              v-if="formData.boolFuncionarioNaoIdentificado"
              v-model="formData.intCadastroGeralSetorId"
              label="Setor"
              name="intCadastroGeralSetorId"
              item-text="strNome"
              route="Cadastro/CadastroGeralSetor"
              :itemList="formData.setor ? [formData.setor] : []"
              :filters="{
                intCadastroGeralEmpresaId: formData.intCadastroGeralEmpresaId,
              }"
              :initializeItems="
                !formData.intId && formData.intCadastroGeralEmpresaId ? true : false
              "
              :disabled="boolCamposBloqueado || !formData.intCadastroGeralEmpresaId"
              clearable
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0"
              v-if="formData.boolFuncionarioNaoIdentificado"
              v-model="formData.intFuncaoId"
              label="Função"
              name="intFuncaoId"
              item-text="strNome"
              route="Cadastro/Funcao"
              :filters="{}"
              :itemList="formData.funcao ? [formData.funcao] : []"
              :initializeItems="!formData.intId ? true : false"
              :disabled="boolCamposBloqueado || !formData.funcionario"
              clearable
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 pr-1"
              v-if="!formData.boolFuncionarioNaoIdentificado"
              v-model="formData.strNome"
              label="Nome"
              name="strNome"
              rules="required"
              :disabled="boolCamposBloqueado"
            />

            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-1"
              v-model="formData.strDataNascimento"
              label="Data Nascimento"
              name="strDataNascimento"
              :hint="$utilidade.calcularIdade(formData.strDataNascimento)"
              :disabled="boolCamposBloqueado"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pa-0 pr-1"
              v-model="formData.strCpf"
              label="CPF"
              name="strCpf"
              v-mask="'999.999.999-99'"
              :disabled="boolCamposBloqueado"
              rules="cpf"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pl-0 pr-1 ma-0 pa-0"
              v-model="formData.intConvenioId"
              name="intConvenioId"
              item-text="strNome"
              label="Convênio"
              route="Cadastro/Convenio"
              :itemList="arrayConvenio"
              :initializeItems="arrayConvenio.length ? false : true"
              @selected="setConvenio"
            />

            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pl-0 pr-0 pa-0"
              v-model="formData.intConvenioPlanoId"
              label="Plano"
              name="intConvenioPlanoId"
              :disabled="!formData.intConvenioId"
              :items="formData.convenio ? formData.convenio.planos : []"
            />

            <div
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 text-center mt-3 mb-3"
              v-if="
                !formData.intId &&
                formData.boolFuncionarioNaoIdentificado &&
                !boolCamposBloqueado
              "
            >
              <router-link
                :to="{
                  name: 'cadastro.funcionario.register',
                }"
                target="_blank"
                style="color: red !important"
                v-if="!formData.intId"
              >
                <i :class="$utilidade.getIcone('cadastrar')" />
                Cadastrar novo Funcionário
              </router-link>
            </div>
          </div>

          <exame
            class="row ma-0 mt-3 mb-3"
            v-if="strTab == 'tab-exame-complemetares'"
            :formData="formData"
          />

          <previa-exame
            class="row ma-0 mt-3 mb-3"
            v-if="strTab == 'tab-exame-previa' && boolExibirPrevia"
            :formData="{
              intFuncionarioId: formData.intFuncionarioId,
              intCadastroGeralUnidadeId: formData.intCadastroGeralUnidadeId,
              intCadastroGeralSetorId: formData.intCadastroGeralSetorId,
              intParametroSubGrupoId: formData.intParametroSubGrupoId,
              intMeses: formData.intMeses,
              strDataConsulta: formData.strDataAgendamento,
            }"
            :key="'tab-exame-previa-' + $root.$session.versao"
          />

          <span class="form-title text-left mb-3">Notificações</span>

          <v-tabs v-model="strTabNotificacao" centered icons-and-text>
            <v-tab href="#tab-notificacao" v-if="formData.intId">
              Notificações
              <i :class="$utilidade.getIcone('notificacao') + ' fa-2x'"></i>
            </v-tab>
            <v-tab
              href="#tab-interacao"
              v-if="formData.interacoes && formData.interacoes.length"
            >
              Interações
              <i :class="$utilidade.getIcone('interacao') + ' fa-2x'"></i>
            </v-tab>
            <v-tab
              href="#tab-notificacao-historico"
              v-if="formData.intId && formData.boolHabilitarNotificacao"
            >
              Histórico de Notificações
              <i :class="$utilidade.getIcone('historico') + ' fa-2x'"></i>
            </v-tab>
          </v-tabs>

          <div class="row ma-0 mt-3 mb-3" v-show="strTabNotificacao == 'tab-notificacao'">
            <ed-input-checkbox
              v-model="formData.boolHabilitarNotificacao"
              class="col-12 pa-0 ma-0"
              name="boolHabilitarNotificacao"
              labelItem="Habilitar notificações"
              :disabled="boolCamposBloqueado ? true : false"
              inline
            />
            <ed-input-text
              v-if="formData.boolHabilitarNotificacao"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 pr-1"
              v-model="formData.strEmail"
              label="Email para enviar as notificações"
              name="strEmail"
              type="email"
              rules="email"
              :disabled="boolCamposBloqueado"
            />
            <ed-input-text
              v-if="formData.boolHabilitarNotificacao"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 pr-1"
              v-model="formData.strCelular"
              label="Celular para enviar as notificações"
              name="strCelular"
              v-mask="'(99) 99999-9999'"
              :disabled="boolCamposBloqueado"
            />
          </div>

          <interacao
            class="row ma-0 mt-3 mb-3"
            v-if="strTabNotificacao == 'tab-interacao'"
            :formData="formData"
          />

          <notificacao
            class="row ma-0 mt-3 mb-3"
            v-if="
              strTabNotificacao == 'tab-notificacao-historico' &&
              formData.boolHabilitarNotificacao
            "
            :pointType="'agendamento'"
            :pointId="formData.intId"
            :key="'tab-notificacao-historico-' + $root.$session.versao"
          />
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
          <campos-personalizado
            :formData="formData"
            :key="'campo-' + $root.$session.versao"
            :intTipoModeloId="319"
            @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
          />
        </div>
      </div>
      <template slot="form-footer">
        <ed-button
          class="mr-1"
          label="Abrir Consulta"
          :iconLeft="$utilidade.getIcone('consulta')"
          size="100%"
          color="success"
          v-if="
            !disabledConsulta &&
            formData.status &&
            formData.intConsultaId &&
            formData.status.strChave == 'agendamentoStatusAtendimentoFinalizado'
          "
          @click="abrirConsulta(formData)"
        />

        <ed-button
          class="mr-1"
          label="Gerar Consulta"
          :iconLeft="$utilidade.getIcone('checkbox')"
          size="100%"
          color="primary"
          v-if="boolGerarAtentimento"
          title="Clique para gerar a consulta e iniciar o atendimento"
          @click="gerarConsulta(true)"
        />

        <ed-button
          class="mr-1"
          label="Cancelar"
          :iconLeft="$utilidade.getIcone('cancelar')"
          size="100%"
          color="error"
          title="Clique para CANCELAR o agendamento"
          v-if="boolExibirCancelamento"
        />
      </template>
      <p style="margin-bottom: 50px"></p>
    </ed-form>
  </div>
</template>

<script>
import {
  EdForm,
  EdAlert,
  EdInputSelect,
  EdInputRadio,
  EdInputSwitch,
  EdInputCheckbox,
  EdInputDate,
  EdInputTime,
  EdInputText,
  EdInputTextArea,
  EdInputAutoComplete,
  EdButton,
  EdSimpleTable,
  EdModal,
} from "@/components/common/form";

import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import PreviaExame from "@/components/consulta/partials/previaExame";
import Notificacao from "@/components/common/notificacao/index";
import Exame from "./exame.vue";
import Interacao from "./interacao.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    disabledConsulta: {
      type: [Boolean],
      required: false,
      default: function () {
        return false;
      },
    },
  },
  components: {
    EdForm,
    EdAlert,
    EdSimpleTable,
    EdInputSelect,
    EdInputRadio,
    EdInputSwitch,
    EdInputCheckbox,
    EdInputDate,
    EdInputTime,
    EdInputText,
    EdInputTextArea,
    EdInputAutoComplete,
    EdButton,
    EdModal,
    CamposPersonalizado,
    PreviaExame,
    Notificacao,
    Exame,
    Interacao,
  },
  inject: ["bind", "getAgendamento", "salvar", "onEdit", "abrirConsulta"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      strTab: "tab-funcionario",
      strTabNotificacao: "tab-notificacao",
      boolExibirFormularioNovaConsulta: false,
      formDataCustomizado: {},
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  computed: {
    arrayAgendamentoStatus() {
      return this.bind.arrayAgendamentoStatus.filter(
        (x) => ["agendamentoStatusCancelado"].indexOf(x.strChave) == -1
      );
    },

    arrayConvenio() {
      let convenios = [];

      if (this.formData.funcionario && this.formData.funcionario.convenios) {
        this.formData.funcionario.convenios.forEach((element) => {
          convenios.push(element.convenio);
        });
      } else if (this.formData.convenio) {
        convenios.push(this.formData.convenio);
      }

      return convenios;
    },

    boolCamposBloqueado() {
      return !(
        this.formData.strDataAgendamento && this.formData.strHoraAgendamentoInicial
      );
    },
    boolExibirPrevia() {
      return (
        this.formData.intFuncionarioId &&
        this.formData.intCadastroGeralUnidadeId &&
        this.formData.intCadastroGeralSetorId &&
        this.formData.intParametroGrupoId &&
        this.formData.intParametroSubGrupoId &&
        this.formData.grupo &&
        this.formData.grupo.strChave == "consultaSubGrupoAso"
      );
    },
    boolExibirCancelamento() {
      return this.formData.intId &&
        this.formData.status &&
        [
          "agendamentoStatusAgendado",
          "agendamentoStatusConfirmado",
          "agendamentoStatusAuardandoAtendimento",
        ].indexOf(this.formData.status.strChave) != -1
        ? true
        : false;
    },
    boolAgendamentoBloqueado() {
      return this.formData.intId &&
        this.formData.status &&
        ["agendamentoStatusCancelado", "agendamentoStatusAtendimentoFinalizado"].indexOf(
          this.formData.status.strChave
        ) != -1
        ? true
        : false;
    },
    boolGerarAtentimento() {
      return this.formData.intId &&
        this.formData.status &&
        [
          "agendamentoStatusAgendado",
          "agendamentoStatusConfirmado",
          "agendamentoStatusAuardandoAtendimento",
        ].indexOf(this.formData.status.strChave) != -1
        ? true
        : false;
    },
  },
  methods: {
    initialize() {
      if (!this.formData.boolFuncionarioNaoIdentificado) {
        // this.formData.funcionario = null;
        // this.formData.intFuncionarioId = null;
        // this.formData.strNome = null;
        // this.formData.strCpf = null;
        // this.formData.strDataNascimento = null;
        // this.formData.strEmail = null;
        // this.formData.strCelular = null;
        // this.formData.intCadastroGeralSetorId = null;
        // this.formData.intCadastroGeralEmpresaId = null;
        // this.formData.intCadastroGeralUnidadeId = null;
        // this.formData.intFuncaoId = null;
        // this.formData.empresas = [];
      }
    },
    setGrupo(obj) {
      if (!obj) return;

      this.formData.grupo = obj;
      this.formData.sub_grupo = null;
    },

    setSubGrupo(obj) {
      if (!obj) return;

      this.formData.sub_grupo = obj;
    },

    setStatus(obj) {
      if (!obj) return;

      this.formData.status = obj;
    },

    setConvenio(obj) {
      if (obj) {
        this.formData.convenio = obj;
      }
    },

    setFuncionario(obj) {
      if (!obj) return;

      this.formData.funcionario = obj;
      this.formData.empresa = obj.empresa;
      this.formData.strNome = obj.strNome;
      this.formData.strCpf = obj.strCpf;
      this.formData.strDataNascimento = obj.strDataNascimento;
      this.formData.strEmail = obj.strEmail;
      this.formData.strCelular = obj.strCelular;
      this.formData.intCadastroGeralSetorId = obj.intCadastroGeralSetorId;
      this.formData.intCadastroGeralEmpresaId = obj.intCadastroGeralEmpresaId;
      this.formData.intCadastroGeralUnidadeId = obj.intCadastroGeralUnidadeId;
      this.formData.intFuncaoId = obj.intFuncaoId;
    },

    getAllowedDate(date) {
      if (this.bind.arrayDatasDisponiveis.indexOf(date) == -1) {
        return false;
      }

      return true;
    },

    getEventColor(date) {
      return "";
    },

    gerarConsulta(boolExibirFormularioNovaConsulta) {
      if (boolExibirFormularioNovaConsulta) {
        this.formDataCustomizado = _.cloneDeep(this.formData);
        this.formDataCustomizado.boolGerarConsulta = true;
        this.formDataCustomizado.intParametroStatusId = this.bind.objStatusAtendimentoIniciado.intId;
        this.formDataCustomizado.strDataCheckin = this.$utilidade.toDate(null, true);
        this.formDataCustomizado.strHoraCheckin = this.$utilidade.toDate(
          null,
          false,
          "HH:MM"
        );
        this.formDataCustomizado.strObservacao = null;
        this.boolExibirFormularioNovaConsulta = true;
        return;
      }

      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Agendamento/Agendamento", this.formDataCustomizado)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$root.$message.success("Consulta gerado com sucesso");
            this.boolExibirFormularioNovaConsulta = false;
            this.getAgendamento();
            this.onEdit(objResponse.result);
          }
        });
    },
  },
};
</script>
